.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 2rem;
    width: 100%;
    max-width: 600px;
    opacity: 1;
    background-color: rgba(158, 158, 158, 0.642);
    transform: translateY(0);
    transition: all 0.5s ease;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    @media (min-width: 768px) {
        left: 2rem;
        bottom: 2rem;
    }
    &.hidden {
        opacity: 0;
        transform: translateY(100%);
    }
    .cookies_policy {
        p {
            font-size: 18px;
            @media (min-width: 768px) {
                font-size: 20px;
            }
            a {
                text-decoration: underline;
                font-size: 18px;
                color: black;
                @media (min-width: 768px) {
                    font-size: 20px;
                }
            }
        }
        .cookies_consent_btn {
            margin-top: 1.5rem;
            padding: 1rem;
            background-color: black;
            color: white;
            cursor: pointer;
            font-size: 16px;
            border: none;
            outline: none;
            border-radius: 0.5rem;
            @media (min-width: 768px) {
                font-size: 18px;
            }
        }
    }
}
